<template>
  <div class="divide-y divide-neutral-100">
    <div class="flex items-center gap-2 h-20 bg-white space-y-2 px-8 py-6 border-bottom">
      <template>
        <ic-channels
          :size="32"
          class="text-bb-brand-purple"
        />
      </template>
      <h2 class="font-medium">Integrations</h2>
    </div>

    <div class="base-scrollbar bg-white main-container p-6">
      <h3 class="font-medium pb-6">Connected Integrations</h3>
      <div class="mb-20 max-w-4xl space-y-6">
        <div v-if="isLoading">
          <brightbid-loader size="120">
            <p>Updating Connected Integrations...</p>
          </brightbid-loader>
        </div>
        <!--AMAZON-->
        <channels-card-modal
          v-if="!isWebsite && siteDetailsLoaded"
          amazon
          :is-active="amazon"
          :site-details="siteDetails"
          :account-refreshed="activeChannel"
          :refresh-connected-accounts="refreshConnectedAccounts"
          @select-channel="selectChannel('amazon')"
          @show-modal="showModal"
        >
          <template #icon>
            <img
              src="/content/images/amazon-icon.svg"
              alt="AMAZON"
            />
          </template>
          <template #card-title> Amazon </template>
          <template #card-description> Connect to your Amazon Storefront </template>
        </channels-card-modal>

        <!--GOOGLE ADS SEARCH-->
        <channels-card-modal
          v-if="siteDetailsLoaded"
          google-ads
          :is-active="googleAds"
          :site-details="siteDetails"
          :account-refreshed="activeChannel"
          :refresh-connected-accounts="refreshConnectedAccounts"
          @select-channel="selectChannel('googleAds')"
          @show-modal="showModal()"
        >
          <template #icon>
            <img
              src="/content/images/g-ads-icon.svg"
              alt="G_ADS_SEARCH"
            />
          </template>
          <template #card-title> Google Ads </template>
          <template #card-description> Connect to your Google Ads account </template>
        </channels-card-modal>

        <!--GOOGLE ADS SHOPPING-->
        <channels-card-modal
          v-if="isWebsite && siteDetailsLoaded"
          google-merchant-center
          :is-active="googleMerchantCenter"
          :site-details="siteDetails"
          :account-refreshed="activeChannel"
          :refresh-connected-accounts="refreshConnectedAccounts"
          @select-channel="selectChannel('googleMerchantCenter')"
          @show-modal="showModal()"
        >
          <template #icon>
            <img
              src="/content/images/gmc-icon.svg"
              alt="G_ADS_SHOPPING"
            />
          </template>
          <template #card-title> Google Shopping </template>
          <template #card-description> Connect to your Merchant Center </template>
        </channels-card-modal>

        <bb-base-modal
          v-if="isModalOpen"
          :width="modalSize.width"
          :height="modalSize.height"
          @close="closeModal"
        >
          <template #container="{}">
            <div class="flex flex-col items-left p-8">
              <h3 class="h3 pb-4">Edit Connected Channels</h3>
              <div>
                <!--AMAZON-->
                <channel-card
                  v-if="amazon"
                  amazon
                  :is-active="amazon"
                  :is-onboarding="false"
                  :apply-changes="applyChanges"
                  :site-details="siteDetails"
                  @connected="hasAConnectedAccount = true"
                  @change-applied="changeApplied"
                  @toggle-modal-loading="toggleModalLoading"
                >
                  <template #icon>
                    <img
                      src="/content/images/amazon-icon.svg"
                      alt="AMAZON"
                    />
                  </template>
                  <template #card-title> Amazon </template>
                  <template #card-description> Connect to your Amazon Storefront </template>
                </channel-card>

                <!--GOOGLE ADS SEARCH-->
                <channel-card
                  v-if="googleAds"
                  google-ads
                  :is-active="googleAds"
                  :is-onboarding="false"
                  :apply-changes="applyChanges"
                  :site-details="siteDetails"
                  @connected="hasAConnectedAccount = true"
                  @change-applied="changeApplied"
                  @toggle-modal-loading="toggleModalLoading"
                >
                  <template #icon>
                    <img
                      src="/content/images/g-ads-icon.svg"
                      alt="G_ADS_SEARCH"
                    />
                  </template>
                  <template #card-title> Google Ads </template>
                  <template #card-description> Connect to your Google Ads account </template>
                </channel-card>

                <!--GOOGLE ADS SHOPPING-->
                <channel-card
                  v-if="googleMerchantCenter"
                  google-merchant-center
                  :is-active="googleMerchantCenter"
                  :is-onboarding="false"
                  :apply-changes="applyChanges"
                  :site-details="siteDetails"
                  @connected="hasAConnectedAccount = true"
                  @change-applied="changeApplied"
                  @toggle-modal-loading="toggleModalLoading"
                >
                  <template #icon>
                    <img
                      src="/content/images/gmc-icon.svg"
                      alt="G_ADS_SHOPPING"
                    />
                  </template>
                  <template #card-title> Google Shopping </template>
                  <template #card-description> Connect to your Merchant Center </template>
                </channel-card>
              </div>

              <!-- Modal footer -->
              <!--SAVE AND CANCEL BUTTONS-->
              <div class="flex justify-between items-center pt-6 mt-2 space-x-8">
                <MergeButtonRound
                  button-type="secondary"
                  class="w-40"
                  @click="closeModal"
                >
                  <p>Cancel</p>
                </MergeButtonRound>
                <MergeButtonRound
                  button-type="primary"
                  class="w-40"
                  @click="saveChanges"
                >
                  <p>Save Changes</p>
                </MergeButtonRound>
              </div>
            </div>
          </template>
        </bb-base-modal>
      </div>
    </div>
  </div>
</template>

<script>
import BrightbidLoader from '@/components/loader/BrightbidLoader'
import ChannelsCardModal from '@/components/settings/manage-channels-components/ChannelsCardModal'
import Toast from '@/components/shared/Toast.vue'
import IcChannels from '@/components/icon/ic-channels.vue'
import { mapState } from 'vuex'

import BbBaseModal from '@/components/modals/brightbid/BbBaseModal'
import MergeButtonRound from '@/components/btn/MergeButtonRound'
import ChannelCard from '@/components/onboard-components/ChannelCard'

export default {
  name: 'ManageChannels',
  components: {
    ChannelsCardModal,
    BrightbidLoader,
    IcChannels,
    MergeButtonRound,
    ChannelCard,
    BbBaseModal,
  },
  provide() {
    return {
      context: this.context,
    }
  },
  data() {
    return {
      isWebsite: false,
      amazon: false,
      googleAds: false,
      googleMerchantCenter: false,
      hasAvailableGoogleStore: false,
      isLoading: false,
      hasAConnectedAccount: false,
      siteDetailsLoaded: false,
      isModalOpen: false,
      modalSize: { width: '600px', height: 'auto' },
      applyChanges: false,
      activeChannel: '',
      refreshConnectedAccounts: false,
    }
  },
  computed: {
    ...mapState('site', ['selectedSite']),
    siteDetails() {
      return {
        siteID: this.selectedSite.value,
        organizationID: this.selectedSite.site_organization_id,
      }
    },
  },
  watch: {
    selectedSite(newSite, oldSite) {
      if (newSite.value !== oldSite.value) {
        this.loadSelectedSiteDetails(newSite)
      }
    },
  },
  async created() {
    await this.loadSelectedSiteDetails()
  },
  methods: {
    selectChannel(channel) {
      this.amazon = false
      this.googleAds = false
      this.googleMerchantCenter = false

      this[channel] = true
      this.activeChannel = channel
    },
    async loadSelectedSiteDetails() {
      this.isLoading = true
      try {
        const response = await this.$http.get(`common/site/${this.selectedSite.value}`)
        this.isWebsite = response?.data?.type == 'standard'
        this.siteDetailsLoaded = true
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: `Failed retrieving site type.`,
            type: 'error',
          },
        })
      }
      this.isLoading = false
    },
    closeModal() {
      this.isModalOpen = false
      this.applyChanges = false
    },
    showModal() {
      this.isModalOpen = !this.isModalOpen
    },
    saveChanges() {
      this.applyChanges = true
    },
    changeApplied(status) {
      if (status === 'success') {
        this.refreshConnectedAccounts = !this.refreshConnectedAccounts
        this.isLoading = false
      }
      this.closeModal()
      this.applyChanges = false
    },
    toggleModalLoading(isLoading) {
      this.isLoading = isLoading
    },
  },
}
</script>

<style lang="scss">
.main-container {
  overflow-y: auto;
  height: calc(100vh - 183px);
}
</style>
