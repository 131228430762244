<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 24 25"
    fill="none"
  >
    <g clip-path="url(#clip0_1462_39346)">
      <circle
        cx="9.67676"
        cy="13.5"
        r="4"
        stroke="currentColor"
        stroke-width="2"
      />
      <circle
        cx="14.3596"
        cy="21.1828"
        r="2.20064"
        transform="rotate(-14.5471 14.3596 21.1828)"
        stroke="currentColor"
        stroke-width="2"
      />
      <circle
        cx="3.75789"
        cy="5.56085"
        r="2.20064"
        transform="rotate(126.913 3.75789 5.56085)"
        stroke="currentColor"
        stroke-width="2"
      />
      <path
        d="M16.1768 6.5L12.6768 10"
        stroke="currentColor"
        stroke-width="2"
      />
      <path
        d="M11.6151 16.5131L13.2054 19.5486"
        stroke="currentColor"
        stroke-width="2"
      />
      <path
        d="M7.67676 9.5L5.07808 6.88098"
        stroke="currentColor"
        stroke-width="2"
      />
      <circle
        cx="18.6768"
        cy="4.5"
        r="3"
        stroke="currentColor"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcChannels',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped></style>
