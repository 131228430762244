<template>
  <div
    class="w-full border rounded-lg border-1"
    :class="{ 'border-bb-brand-purple': isActive, 'border-bb-neutral-100': !isActive }"
  >
    <div
      :class="[isActive ? 'bg-neutral-0' : 'bg-white']"
      class="h-24 rounded-lg"
      @click="$emit('select-channel')"
    >
      <!--HEADER-->
      <div class="flex justify-between items-center px-4 py-6 cursor-pointer">
        <div class="flex gap-4 items-center">
          <div class="w-12 flex justify-center">
            <slot name="icon" />
          </div>
          <div class="min-w-xs max-w-md w-auto">
            <h5 class="h5">
              <slot name="card-title" />
            </h5>
            <p class="text-sm text-bb-text-secondary">
              <slot name="card-description" />
            </p>
          </div>
        </div>
        <template v-if="hasConnectedAccount && connectedAccountID && !isLoading">
          <button
            class="btn text-sm flex items-center px-3 rounded-full transition duration-300 focus:outline-none active:text-bb-brand-purple hover:font-bold hover:rounded-full hover:bg-neutral-50"
            @click="$emit('show-modal')"
          >
            <bb-icon
              icon="ic-reset"
              :size="16"
            />
            <span class="m-2"> Change Account </span>
          </button>
          <div class="text-sm rounded-full px-3 text-right flex">
            <div v-if="googleAds">Google Ads ID:  </div>
            <div v-else-if="googleMerchantCenter">Merchant Center ID:  </div>
            <div v-else-if="amazon">Amazon Advertising ID:  </div>
            <div class="font-bold">
              {{ connectedAccountID }}
            </div>
          </div>
          <div class="text-sm bg-bb-success-bg text-bb-success-text rounded-full px-3">Connected</div>
        </template>
        <brightbid-loader
          v-else-if="isLoading"
          size="14"
        />
        <MergeButtonRound
          v-else
          button-type="primary"
          class="w-40"
          @click="$emit('show-modal')"
        >
          <p>Connect</p>
        </MergeButtonRound>
      </div>
    </div>
  </div>
</template>

<script>
import MergeButtonRound from '@/components/btn/MergeButtonRound'
import BrightbidLoader from '@/components/loader/BrightbidLoader'
import Toast from '@/components/shared/Toast.vue'

export default {
  name: 'ChannelsCardModal',
  components: {
    MergeButtonRound,
    BrightbidLoader,
  },
  props: {
    googleAds: {
      type: Boolean,
      default: false,
    },
    googleMerchantCenter: {
      type: Boolean,
      default: false,
    },
    amazon: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    border: {
      type: String,
      default: '',
      validator: val => ['top', 'bottom', ''].includes(val),
    },
    rounded: {
      type: String,
      default: '',
      validator: val => ['top', 'bottom', ''].includes(val),
    },
    siteDetails: {
      type: Object,
      default: function () {
        return {
          organizationID: '',
          siteID: '',
        }
      },
    },
    refreshConnectedAccounts: {
      type: Boolean,
      default: false,
    },
    accountRefreshed: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hasConnectedAccount: false,
      connectedAccountID: null,
      organizationID: this.siteDetails.organizationID,
      siteID: this.siteDetails.siteID,
      isLoading: true,
    }
  },
  watch: {
    siteDetails(newSite, oldSite) {
      if (newSite.siteID !== oldSite.siteID) {
        this.organizationID = this.siteDetails.organizationID
        this.siteID = this.siteDetails.siteID
        this.loadConnectedAccounts()
      }
    },
    refreshConnectedAccounts() {
      this.loadConnectedAccounts(this.accountRefreshed)
    },
  },
  async mounted() {
    this.loadConnectedAccounts()
  },
  methods: {
    async fetchIntegrationData(endpoint, connectedAccountIdKey) {
      this.isLoading = true
      const resp = await this.$http.get(`common/site/${this.siteID}/integration/google/${endpoint}`)
      const hasConnectedAccount = resp.data?.site_integration_data?.[connectedAccountIdKey] !== undefined

      if (hasConnectedAccount) {
        this.connectedAccountID = resp?.data?.site_integration_data?.[connectedAccountIdKey]
        this.onConnectedAccount(hasConnectedAccount)
      } else {
        this.connectedAccountID = null
      }
      this.isLoading = false
    },
    async loadConnectedAccounts(refreshAccount) {
      try {
        if (this.googleAds && (refreshAccount === 'googleAds' || !refreshAccount)) {
          await this.fetchIntegrationData('ads', 'google_ads_customer_id')
        }
        if (this.googleMerchantCenter && (refreshAccount === 'googleMerchantCenter' || !refreshAccount)) {
          await this.fetchIntegrationData('content-api', 'google_gmc_customer_id')
        }
      } catch (error) {
        console.log(error)
        this.onConnectedAccount(false)

        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: `Failed retrieving connected accounts.`,
            type: 'error',
          },
        })
      }
    },
    onConnectedAccount(value) {
      this.hasConnectedAccount = value
      if (value === true) {
        this.$emit('connected')
      }
    },
  },
}
</script>
